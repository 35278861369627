footer{
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-top: 20px;
    width: 100%;
    height: 100px;
}
/* 
.hidderFooter {
    transform: translateY(70px);
    animation: 0.1s linear hideFooter;
}

@keyframes hideFooter {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(70px);
    }
} */

footer p{
    margin: 20px 0;
}

#footer .footerBackground{
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

#footer #puces{
  width: 40%;
  margin-left: 60%;
  display: flex;
  justify-content: center;
  z-index: 10;
  height: 100%;
  align-items: baseline;
}

#footer #puces .puce{
  width: 100px;
}

@media screen and (max-width: 500px) {
  #footer #puces .puce{
      width: 50%;
      min-width: 0;
  }
  #footer #puces{
    align-items: start;
  }
  footer{
    height: 20vw;
  }
}


/* --------- popup  --------- */

.popups{
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 20;
}

.shadow{
  background-color: rgba(0, 0, 0, 0.504);
  height: 100vh;
}

.radiusBorder{
  position: absolute;
  bottom: -8px;
  background-color: white;
  width: 100%;
  border-radius: 8px;
}

.popup {
  padding: 10px 10px 50px 10px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 500px;
  margin: auto;
}

.popup h3{
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

.popup textarea{
  background-color: #EEEDEE;
  border-radius: 8px;
  border: 0px;
  width: calc(100% - 20px);
  height: 70px;
  resize: vertical;
  padding: 10px;
  font-family: 'PoppinsRegular';
}

.popup .ratingStar{
  width: 100%;
  max-width: 220px;
  max-height: 35px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.popup .ratingStar img{
  height: 30px;
  width: 30px;
}

.popups .popupComment p{
  background-color: #EEEDEE;
  padding: 10px;
  font-size: 12px;
  margin-left: 10px;
  border-radius: 10px;
}

.popups .popupComment .messageContent{
  display: flex;
  height: 50px;
  align-items: center;
}

.popups .popupComment .gmSmall{
  height: 100%;
}

.popups .popupMerci{  
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.popups .popupMerci .merci{  
  width: 70px;
  margin: 10% 0;
}

.ratingStar svg{
  width: 100%;
}