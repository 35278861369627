/* O Animation */

.O-stroke {
    stroke-dasharray: 302px;
    stroke-dashoffset: 302px;
    animation: O-move 20s ease forwards;
}
  
    @keyframes O-move {
        0% {
        stroke-dashoffset: 302px;
        }
        4% {
        stroke-dashoffset: 302px;
        }
        9% {
        stroke-dashoffset: 0px;
        }
        100% {
        stroke-dashoffset: 0px;
        }
    }
  
/* Red Dot Animation */

.red-dot {
    stroke-width: 40px;
    stroke-linecap: round;
    animation: red-dot-grow 8s ease-out forwards;
}
  
    @keyframes red-dot-grow {
        0% {
        stroke-width: 0px;
        }
        15% {
        stroke-width: 0px;
        }
        20% {
        stroke-width: 40px;
        }
        100% {
        stroke-width: 40px;
        }
    }

/* O/2 Animation */

.O-2-stroke {
    stroke-dasharray: 320px;
    stroke-dashoffset: 320px;
    animation: O-2-move 20s ease forwards;
}
  
    @keyframes O-2-move {
        0% {
            stroke-dashoffset: 320px;
        }
        4% {
            stroke-dashoffset: 320px;
        }
        7% {
            stroke-dashoffset: 190px;
        }
        100% {
            stroke-dashoffset: 190px;
        }
    }

/* SVGLogo */

.LogoText{
    animation: LogoText 20s ease forwards;
}


@keyframes LogoText {
    0% {
        scale: 0;
        opacity: 0;
    }
    5% {
        opacity: 0.5;
    }
    7%{
        scale: 1;
        opacity: 1;
    }
    10% {
        scale: 1;
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}