.typeDeDechetPage .module{
  background-color: var(--grey);
  border-radius: 20px;
  height: 130px;
  max-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 4px 3px 4px rgba(0, 0, 0, 0.2);
}

/* .typeDeDechetPage .containerModulePlus {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "module-1 . . . ."
      "module-1 . module-2 . ."
      "module-1 . module-2 . module-3"
      ". . module-2 . module-3"
      "module-4 . . . module-3"
      "module-4 . module-5 . ."
      "module-4 . module-5 . module-6"
      ". . module-5 . module-6"
      "module-7 . . . module-6"
      "module-7 . module-8 . ."
      "module-7 . module-8 . module-9"
      ". . module-8 . module-9"
      ". . . . module-9"; 
} */

.containerModule {  display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      ". module-left . . . . ."
      ". module-left . module-center . . ."
      ". module-left . module-center . module-right ."
      ". . . module-center . module-right ."
      ". . . . . module-right ."
  }
  
  .module-left { grid-area: module-left; }
  
  .module-center { grid-area: module-center; }
  
  .module-right { grid-area: module-right; }
  

.typeDeDechetPage .containerModule div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.typeDeDechetPage .containerModule div p{    
    position: absolute;
    margin-top: 167px;
    margin-bottom: 0;
    font-size: 10px;
    font-family: 'PoppinsRegular';
    width: 100px;
    line-height: 10px;
}


/* .typeDeDechetPage .module-1 { grid-area: module-1; }
.typeDeDechetPage .module-2 { grid-area: module-2; }
.typeDeDechetPage .module-3 { grid-area: module-3; }
.typeDeDechetPage .module-4 { grid-area: module-4; }
.typeDeDechetPage .module-5 { grid-area: module-5; }
.typeDeDechetPage .module-6 { grid-area: module-6; }
.typeDeDechetPage .module-7 { grid-area: module-7; }
.typeDeDechetPage .module-8 { grid-area: module-8; }
.typeDeDechetPage .module-9 { grid-area: module-9; } */

@media screen and (min-width: 500px) {
    .typeDeDechetPage{
        margin: auto;
    }
    .typeDeDechetPage h3{
        margin-left: 10px;
    }
}

.typeDeDechetPage .puceText{
    position: absolute;
    left: 0;
    height: 100%;
}

@media screen and (max-width: 630px) {
    .typeDeDechetPage .centerTexte{
        left: 40px;
        position: relative;
    }
}


.containerModule4 {  display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr 1fr 0.5fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      ". . . . ."
      ". module-1 . . ."
      ". module-1 . module-2 ."
      ". module-1 . module-2 ."
      ". . . module-2 ."
      ". module-3 . . ."
      ". module-3 . module-4 ."
      ". module-3 . module-4 ."
      ". . . module-4 .";
  }
  
  .module-1 { grid-area: module-1; }
  
  .module-2 { grid-area: module-2; }
  
  .module-3 { grid-area: module-3; }
  
  .module-4 { grid-area: module-4; }