.bornePage{
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.bornePage p{
    text-align: justify;
    margin: 50px 10px;
    font-size: 14px;
}

.bornePage .borneImg{
    width: 100%;
    margin-bottom: 30px;
}

.bornePage .descriptionElement{
    display: flex;
    font-size: 10px;
    justify-content: space-around;
}

.bornePage .descriptionElement h4{
    text-align: center;
    margin-bottom: 30px;
}

.bornePage .descriptionElement ul{
    padding-left: 15px;
}

.bornePage .separateurTextBorne{
    object-fit: cover;
    margin: 10px;
}

.bornePage .trueElement{
    max-width: 50%;
}