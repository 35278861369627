.homePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 750px;
}

.homePage .content {
    height: 50%;
    max-height: 400px;
    max-width: 300px;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    width: 80%;
}

.homePage .Gmclassic{
    width: 50%;
}

.load {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.load .Gmclassic{
    width: 50%;
}

.contentVideo {
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}

.contentVideo div:first-child{
    display: none;
}

.contentVideo .filter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 8vw solid var(--green);
    opacity: 0.5;
    box-sizing: border-box;
    animation: Qrcode 1.5s linear infinite;
}

.contentVideo video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* transform: scaleX(-1); */
}

@keyframes Qrcode {
    0%{border-width: 40px;}
    50%{border-width: 50px;}
    100%{border-width: 40px;}
}

.homePage .activeButton{
    background-color: var(--green);
}

.homePage .flags{
    display: flex;
    justify-content: space-around;
    max-height: 50px;
    width: 50%;
}

.homePage .flags img {
    width: 40%;
}

.homePage .footer{
    display: flex;
}