.demoContactPage .form{
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 500px;
    height: 650px;
    justify-content: space-around;
    font-size: 14px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}

.demoContactPage .backgroundForm{
    background-color: #4d492918;
    padding: 50px 0;
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .demoContactPage .form {
        width: 90vw;
        min-width: 0px;
    }
}

.demoContactPage .form p {
    text-align: center;
    font-size: 25px;
    margin: 10px 0;
    font-family: 'PoppinsMedium';
}

.demoContactPage .form h2{
    text-align: center;
    color: var(--green);
    font-size: 35px;
    margin: 0;
}

.demoContactPage .form input{
    border: 0;
    border-bottom: 1px solid black;
    /* height: 20px; */
}

.demoContactPage .form textarea{
    height: 70px;
    resize: vertical;
    font-size: 14px;
    font-family: 'PoppinsRegular';
    padding: 10px;
    border-radius: 8px;
    border: 0px;
    background-color: #EEEDEE;
}

.demoContactPage .activeButton{
    background-color: var(--green);
    height: 30px;
    margin-top: 10px;
    border-radius: 5px;
    border: 0;
}

.demoContactPage .form input::placeholder{
    color: #bebebe;
}

textarea::placeholder{
    color: #bebebe;
}

/* 1er article */

.demoContactPage .firstActicle .calqueVideo{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: absolute;
    top: 0;
    background-color: #000000a1;
}

.demoContactPage .firstActicle video{
    object-fit: cover;
}

.demoContactPage .firstActicle .calqueVideo h2{
    margin: 0 20px;
    font-size: 50px;
    text-transform: uppercase;
    color: white;
    line-height: 1;
    text-align: center;
}

.demoContactPage .firstActicle .calqueVideo p{
    font-size: 30px;
    margin: 0;
    color: white;
    font-family: 'BradleyHand';
    margin-top: 10px;
}

.demoContactPage .firstActicle .calqueVideo img{
    margin: 40px 0;
    max-width: 25%;
    max-height: 25%;
}

article .imgArticle{
    width: 50%;
    object-fit: cover;
}

@media screen and (max-width: 1000px) {
    .demoContactPage .firstActicle .calqueVideo p{
        font-size: 4.5vw;
    }
}

@media screen and (max-width: 1000px) {
    .demoContactPage .firstActicle .calqueVideo h2{
        font-size: 5.2vw;
    }
}

@media screen and (max-width: 850px) {
    .demoContactPage article{
        flex-direction: column;
    }
    .demoContactPage article .imgArticle{
        width: 100%;
        height: 35vh;
    }    
    .demoContactPage article .partieColor{
        height: 65vh;
    }
}

@media screen and (max-width: 500px) {
    .demoContactPage article p{
        font-size: 3vw;
    }    
    .demoContactPage article h2{
        font-size: 5vw;
    }
}

article{
    display: flex;
    height: 100vh;
}

article .partieColor{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 10%;
}

article .partieColorBlue{
    background-color: #A4D1FF;
}

article .partieColorOrange{
    background-color: #ff9d35;
}

article .logoMini{
    width: 150px;
    max-width: 25%;
    margin: 0px auto;
}

.demoContactPage .footerDemo{
    background-color: black;
    position: relative;
    margin: 0;
    color: white;
    align-items: center;
    height: 50px;
}

.demoContactPage .footerDemo img{
    height: 60%;
}

.demoContactPage .callToAction{
    width: 40px;
    height: 40px;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
    left: calc(50% - 25px);
    z-index: 10000;
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
    -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
    user-select: none; /* Propriété standard */
    opacity: 0.8;
}

.demoContactPage .callToAction img{
    transform: rotateZ(90deg);
    scale: 0.6;
}