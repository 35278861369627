.demandeDeServicePage .containerModule {  display: grid;
    grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "module-text . . . ."
      "module-text . module-1 . ."
      "module-text . module-1 . module-2"
      ". . module-1 . module-2"
      ". . . . module-2"
      ". . module-3 . ."
      ". . module-3 . module-4"
      ". . module-3 . module-4"
      ". . . . module-4";
}

.demandeDeServicePage .containerModule2 {  display: grid;
    grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "module-text . . . ."
      "module-text . module-1 . ."
      "module-text . module-1 . module-2"
      ". . module-1 . module-2"
      ". . . . module-2"
}

.module-text { grid-area: module-text; }

.module-1 { grid-area: module-1; }

.module-2 { grid-area: module-2; }

.module-3 { grid-area: module-3; }

.module-4 { grid-area: module-4; }
  
.demandeDeServicePage .module{
    background-color: var(--grey);
    border-radius: 20px;
    height: 140px;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    box-shadow: 4px 3px 4px rgba(0, 0, 0, 0.2);
}

.demandeDeServicePage .locations{
    /* border: 1px solid var(--green); */
    padding: 10px;
    margin: 20px 0;
    height: 30px;
}

.demandeDeServicePage .location{
    border:  none;
    margin: 0;
    height: 40px;
    font-size: 16px;
    background-color: white;
}

.demandeDeServicePage .inputLocation{
    width: 66%;
}

.demandeDeServicePage .selectLocation{
    border-bottom-right-radius: 5px;
    width: 25%;
    padding-left: 2%;
}

.demandeDeServicePage .module-text{
    font-size: 12px;
    font-family: 'poppinsRegular';
    position: absolute;
}

.demandeDeServicePage .module-text p{
    margin: 30px 0;
}

/* ---------- popup -------------*/

.demandeDeServicePage .popups{
    bottom: 0;
    left: 0;
}