@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('../assets/font/Poppins/Poppins-SemiBold.ttf');
}
@font-face {
    font-family: 'PoppinsRegular';
    src: url('../assets/font/Poppins/Poppins-Regular.ttf');
}
@font-face {
    font-family: 'PoppinsBold';
    src: url('../assets/font/Poppins/Poppins-Bold.ttf');
}
@font-face {
    font-family: 'PoppinsMedium';
    src: url("../assets/font/Poppins/Poppins-Medium.ttf");
}

@font-face {
    font-family: 'PoppinsLight';
    src: url("../assets/font/Poppins/Poppins-Light.ttf");
}

@font-face {
    font-family: 'BradleyHand';
    src: url("../assets/font/BradleyHand/Bradley-Hand.ttf");
}

body{
    font-family: 'PoppinsMedium';
}

:root {
    --grey: #EEEDEE;
    --purple: #7C2A90;
    --green: rgb(27, 154, 104);
    --lightpurple: #D5D2D6;
    --black: #2F2F2F;
    --red: #C53030;
    --white: #ffffff;
}

.error{
    color: var(--red);
}

h3{
    font-family: 'PoppinsBold';
    text-transform: uppercase;
    width: 115px;
    margin: 50px 0;
    line-height: 1;
}

.activeButton{
    border: 1px solid white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 44px;
    width: 100%;
}

.button{
    border: 1px solid var(--green);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--green);
    height: 44px;
}

.page{
    max-width: 500px;
    margin: auto;
    margin-bottom: 130px;
}

@media screen and (max-width: 500px) {
    .page{
        margin: 0 20px;
        margin-bottom: 130px;
    }
}


/* tittle page */ 

.titlePage{
    display: flex;
    align-items: center;
}

.titlePage img{
    width: 50px;
    height: 50px;
}

.titlePage h3{
    margin: 0;
}

.centerTexte{
    margin: 50px 0;
    border-left: 2px solid black;
    padding-left: 10px;
    height: 40px;
    display: flex;
    align-items: center;
}

body {
    background-color: white; /* fond blanc par défaut */
    color: black; /* texte noir par défaut */
}

@media (prefers-color-scheme: dark) {
    body {
        background-color: white; /* fond noir en mode sombre */
        color: black; /* texte blanc en mode sombre */
    }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #A4A4A4 #DFE9EB;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
    width: 0px;
}  

input{
    font-size: 16px;
}

select{
    color: black;
}

textarea{
    font-size: 16px;
}