header{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.fixHeader{
    position: sticky;
    top: 0;
    width: calc( 100vw - 20px );
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.596);
    color: white;
    height: 20px;
    padding: 10px;
    z-index: 10;
}

header .brandLogo{
    height: 70%;
    max-width: 70%;
}

.fixHeader .containerLogo{
    height: 100%;
    display: flex;
    align-items: center;
}
.fixHeader .logoDemo{
    height: 80%;
}

.fixHeader .rightPart{
    height: 60%;
    display: flex;
    justify-content: flex-end;
}

.fixHeader .rightPart .imgContact{
    height: 100%;
    margin-right: 20px;
}

.fixHeader .flagSelect{
    display: flex;
    min-width: 80px;
}

.fixHeader .flagSelect img{
    margin:0 ;
    padding: 0;
    width: 25%;
}

.fixHeader .rightPartElement{
    /* width: 30%; */
    text-align: center;
    font-size: 10px;
}

header .retourButton{
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 10px;
}