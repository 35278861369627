.mainPage .noBckImg{
    background-color: #DFDFDF;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainPage .bckImg{
    height: 60vh;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.mainPage .bckImg svg{
    background-color: #f3f3f340;
    border-radius: 17px;
}

.mainPage h2{
    font-size: 16px;
    text-align: center;
    margin: 30px 0;
}

.mainPage .sliderModule{
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 150px;
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
}

.mainPage .noSliderModule{
    justify-content: space-around;
    display: flex;
    height: 150px;
    display: flex;
    margin: 0 auto;
    width: 50%;
}

.mainPage .sliderModule::-webkit-scrollbar {
    height: 2px;
    background-color: #ffffff;
}
  
.mainPage .sliderModule::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
    height: 2px;
}

.mainPage .module{
    height: 140px;
    width: 100px;
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    box-shadow: 4px -3px 4px rgba(0, 0, 0, 0.2);
    border-radius: 13px;
    background-image: url("../assets/svg/puceActive.svg");
    background-size: cover;
    flex-direction: column;;
}

.mainPage .desac{
    /* background-color: #525252; */
    background-image: url("../assets/svg/puceDesactive.svg");
}

.mainPage .masquer{
    background-image: url("../assets/svg/puceVisible.svg");
}

.mainPage .textModule{
    margin-bottom: 0;
    font-size: 10px;
    font-family: 'PoppinsRegular';
    text-align: center;
    line-height: 10px;
}

.mainPage .module img{
    width: 85px;
}